<template>
  <div class="matters-table-class">
    <GenericBTable
      v-if="matterListFilterByCycle.length > 0"
      :items="matterListFilterByCycle"
      :fields="fields"
      :pagination="matterListFilterByCycle.length"
      :default_borderless="false"
      :show_pagination="false"
      :search_filter="false"
      :columns_display="false"
    >
      <!-- matter_code -->
      <template v-slot:cell(matter_code)="row">
        <template v-if="row.item.get_matter">
          {{ row.item.get_matter.code }}
        </template>
      </template>
      <!-- matter_name -->
      <template v-slot:cell(matter_name)="row">
        <template v-if="row.item.get_matter">
          {{ row.item.get_matter.name }}
        </template>
      </template>
      <!-- total_pedagogical_hours -->
      <template v-slot:cell(total_pedagogical_hours)="row">
        {{ semesterClassroonHours(row.item) }}
      </template>
      <!-- credits -->
      <template v-slot:cell(credits)="row">
        <template v-if="row.item.use_matter_values">
          <template v-if="row.item.get_matter">
            {{ row.item.get_matter.credits }}
          </template>
        </template>
        <template v-else>
          {{ row.item.credits }}
        </template>
      </template>
      <!-- credits -->
      <template v-slot:cell(institutional_credits)="row">
        <template v-if="row.item.use_matter_values">
          <template v-if="row.item.get_matter">
            {{ row.item.get_matter.institutional_credits }}
          </template>
        </template>
        <template v-else>
          {{ row.item.institutional_credits }}
        </template>
      </template>
      <!-- modalities -->
      <template v-slot:cell(modalities)="row">
        <div :id="`sumary-table-matter-modalities-${row.item.id}`">
          {{ modalitiesByOrder(row.item) }}
          <template v-if="row.item.use_matter_values && row.item.get_matter">
            <ModalitiesPopover
              :object="row.item"
              :content="matterModalities(row.item.get_matter.modalities)"
              :label_triggers="'sumary-table-matter-modalities-'"
              :triggers="'hover'"
              :placement="'left'"
            ></ModalitiesPopover>
          </template>
          <template v-else-if="!row.item.use_matter_values">
            <ModalitiesPopover
              :object="row.item"
              :content="matterModalities(row.item.modalities)"
              :label_triggers="'sumary-table-matter-modalities-'"
              :triggers="'hover'"
              :placement="'left'"
            ></ModalitiesPopover>
          </template>
        </div>
      </template>
      <!-- matter_prerequisites -->
      <template v-slot:cell(matter_prerequisites)="row">
        <div
          :id="`sumary-table-matter-prerequisites-${row.item.id}`"
          class="pointer"
        >
          {{ prerequisitesOrder(row.item) }}
          <PrerequisitesPopover
            :object="row.item"
            :label_triggers="'sumary-table-matter-prerequisites-'"
            :content="prerequisitesFiltered(row.item)"
            :placement="'right'"
            :triggers="'hover focus'"
            :title="'Prerrequisitos'"
          >
          </PrerequisitesPopover>
        </div>
      </template>
      <!-- matter_competence -->
      <template v-slot:cell(matter_competence)="row">
        <div
          class="pointer"
          :id="`sumary-table-matter-competences-${row.item.id}`"
        >
          {{ competencesOrderedList(row.item) }}
          <b-popover
            :target="`sumary-table-matter-competences-${row.item.id}`"
            triggers="hover"
            placement="righttop"
          >
            <template #title>
              {{ $getVisibleNames("mesh.competence", true, "Competencias") }}
            </template>
            <template v-if="competencesList(row.item).length">
              <div
                v-for="competence in competencesList(row.item)"
                :key="competence.id"
              >
                <div :key="competence.id" class="d-flex">
                  {{
                    competence.profile_cycle
                      ? competence.order + "." + competence.profile_cycle.order
                      : competence.order + "."
                  }}
                  <SentenceContainer
                    :Sentence="competence"
                    :boundedTextContainer="true"
                  ></SentenceContainer>
                </div>
              </div>
            </template>
            <template v-else>
              <div>
                Esta
                {{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    false,
                    "Asignatura"
                  )
                }}
                no tiene ninguna
                {{ $getVisibleNames("mesh.competence", false, "Competencia") }}
                asociada.
              </div>
            </template>
          </b-popover>
        </div>
      </template>
    </GenericBTable>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "SumaryTableOfMatters",
  components: {
    GenericBTable: () => import("@/components/reusable/GenericBTable.vue"),
    PrerequisitesPopover: () =>
      import("@/components/reusable/PrerequisitesPopover"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    ModalitiesPopover: () => import("@/components/reusable/ModalitiesPopover"),
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    cycle_id: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      egress_profiles: names.EGRESS_PROFILES,
      competences: names.COMPETENCES,
      profile_competences: names.PROFILE_COMPETENCES,
      egress_profile_cycles: names.PROFILE_CYCLES,
      matters: names.MATTERS,
      courses_wrapper: names.COURSES_WRAPPER,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      regimes: names.REGIMES,
      modalities: names.MODALITIES,
      profile_types: names.PROFILE_TYPES,
      time_allocations: names.TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
    }),
    egressProfile() {
      return this.egress_profiles.find((x) => x.id == this.egress_profile_id);
    },
    isAnual() {
      if (!this.egressProfile) return null;
      const months_amount_egress_profile = this.regimes.find(
        (x) => x.id == this.egressProfile.regime
      );
      if (months_amount_egress_profile) {
        if (months_amount_egress_profile.months_amount == 6)
          return this.regimes.find(
            (x) =>
              x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
          );
        else if (months_amount_egress_profile.months_amount == 3)
          return this.regimes.find(
            (x) =>
              x.name.includes("Semestral") || parseInt(x.months_amount) / 3 == 2
          );
        else if (months_amount_egress_profile.months_amount == 2)
          return this.regimes.find(
            (x) =>
              x.name.includes("Trimestral") || parseInt(x.months_amount) == 3
          );
      }
      return this.regimes.find(
        (x) => x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
      );
    },
    profileType() {
      if (!this.egressProfile) return null;
      return this.profile_types.find(
        (x) => x.id == this.egressProfile.egress_profile_type
      );
    },
    coursesWrapperList() {
      return this.courses_wrapper
        .filter((x) => x.egress_profile == this.egress_profile_id)
        .sort(function (a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          if (a.init_level < b.init_level) return -1;
          if (a.init_level > b.init_level) return 1;
          if (a.end_level < b.end_level) return -1;
          if (a.end_level > b.end_level) return 1;
          return 0;
        });
    },
    wrapperMatterIds() {
      let list = [];
      this.coursesWrapperList.forEach((x) => {
        list = list.concat(x.egress_profile_matters);
      });
      return [...new Set(list)];
    },
    profileCompetences() {
      return this.profile_competences.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
    },
    matterList() {
      return this.egress_profiles_matters
        .filter(
          (x) =>
            x.egress_profile == this.egress_profile_id &&
            !this.wrapperMatterIds.includes(x.id)
        )
        .map((x) => {
          const get_matter = this.matters.find((m) => m.id === x.matter);
          return { ...x, get_matter: get_matter ? get_matter : null };
        });
    },
    matterListFilterByCycle() {
      if (!this.cycle_id) return this.matterList;
      else {
        const cycle = this.egress_profile_cycles.find(
          (x) =>
            x.cycle == this.cycle_id &&
            x.egress_profile == this.egress_profile_id
        );
        if (cycle) {
          const matters_filter = this.matterList.filter(
            (x) =>
              (x.plan_level >= cycle.init_semester &&
                x.plan_level <= cycle.end_semester) ||
              (this.isAnual &&
                this.isAnual.id == x.regime &&
                parseFloat(x.plan_level) + 1 >= cycle.init_semester &&
                parseFloat(x.plan_level) + 1 <= cycle.end_semester)
          );
          const prerequisites = [
            ...new Set(matters_filter.map((x) => x.prerequisites).flat()),
          ];
          const matter_filter_ids = matters_filter.map((x) => x.id);
          return this.matterList.filter(
            (x) =>
              matter_filter_ids.includes(x.id) || prerequisites.includes(x.id)
          );
        } else return [];
      }
    },
    fields() {
      return [
        {
          key: "order",
          label: `N° de la ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )}`,
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-center",
          display_column: true,
        },
        {
          key: "matter_code",
          label: String(
            this.$getVisibleNames("manual.matter_code", false, "Código")
          ),
          sortable: false,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-center",
          display_column: true,
        },
        {
          key: "matter_name",
          label: String(
            this.$getVisibleNames(
              "mesh.egressprofilematter",
              false,
              "Asignatura"
            )
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "plan_level",
          label: "Nivel del Plan",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-center",
          display_column: true,
        },
        {
          key: "total_pedagogical_hours",
          label: this.$getVisibleNames(
            "manual.total_pedagogical_hours",
            true,
            "Total de Horas Pedagógicas"
          ),
          sortable: false,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-center",
          display_column: true,
        },
        {
          key: "credits",
          label: this.$getVisibleNames("manual.credits", true, "Créditos SCT"),
          sortable: false,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-center",
          display_column: true,
        },
        {
          key: "institutional_credits",
          label: this.$getVisibleNames(
            "manual.institutional_credits",
            true,
            "Créditos Institucionales"
          ),
          sortable: false,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-center",
          display_column:
            this.institution && this.institution.internal_use_id == "duoc_uc",
        },
        {
          key: "modalities",
          label: this.$getVisibleNames(
            "mesh.modality",
            false,
            "Modalidad de Asignaturas"
          ),
          sortable: false,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "matter_prerequisites",
          label: "Prerrequisitos",
          sortable: false,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "matter_competence",
          label: this.$getVisibleNames("mesh.competence", true, "Competencias"),
          sortable: false,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column:
            this.institution && this.institution.internal_use_id == "duoc_uc",
        },
      ];
    },
  },
  methods: {
    prerequisitesOrder(egress_profile_matter) {
      let list = this.matterList
        .filter(
          (x) =>
            egress_profile_matter.prerequisites.includes(x.id) &&
            x.get_matter != null
        )
        .map((ep_matter) => ep_matter.order);
      list.sort(function (a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
      if (list.length == 0) return "-";
      return list.join(", ");
    },
    prerequisitesFiltered(egress_profile_matter) {
      let list = this.matterList
        .filter(
          (x) =>
            egress_profile_matter.prerequisites.includes(x.id) &&
            x.get_matter != null
        )
        .map((ep_matter) => {
          return {
            ...ep_matter.get_matter,
            formation_line: ep_matter.formation_line,
            order: ep_matter.order,
          };
        });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
    competencesList(egress_profile_matter) {
      let list = this.profileCompetences
        .filter((x) => egress_profile_matter.competences.includes(x.competence))
        .map((ep_competence) => {
          const cycle = this.egress_profile_cycles.find(
            (ep_cycle) =>
              ep_cycle.egress_profile == ep_competence.egress_profile &&
              ep_cycle.cycle == ep_competence.cycle
          );
          const get_cycle = cycle ? cycle : null;
          const profile_cycle_order = cycle ? cycle.order : 0;
          const get_competence = this.competences.find(
            (competence) =>
              competence.id == ep_competence.competence &&
              egress_profile_matter.competences.includes(competence.id)
          );
          if (get_competence)
            return {
              id: ep_competence.competence,
              order: ep_competence.order,
              egress_profile: ep_competence.egress_profile,
              cycle: ep_competence.cycle,
              action: get_competence.action,
              content: get_competence.content,
              condition: get_competence.condition,
              context: get_competence.context,
              type: get_competence.type,
              verb: get_competence.verb,
              profile_cycle: get_cycle,
              profile_cycle_order: profile_cycle_order,
              full_sentence: get_competence.full_sentence,
              name: get_competence.name,
            };
        });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        if (a.profile_cycle_order > b.profile_cycle_order) return 1;
        if (a.profile_cycle_order < b.profile_cycle_order) return -1;
        return 0;
      });
    },
    competencesOrderedList(egress_profile_matter) {
      let list = [];
      this.competencesList(egress_profile_matter).forEach((competence) => {
        if (competence.profile_cycle_order == 0) list.push(competence.order);
        else list.push(competence.order + "." + competence.profile_cycle_order);
      });
      if (list.length == 0) return "-";
      return list.join(", ");
    },
    matterModalities(modalities_ids) {
      return this.modalities.filter((x) => modalities_ids.includes(x.id));
    },
    modalitiesByOrder(egress_profile_matter) {
      let list = [];
      if (
        egress_profile_matter.use_matter_values &&
        egress_profile_matter.get_matter
      ) {
        list = this.matterModalities(
          egress_profile_matter.get_matter.modalities
        ).map((element) => element.order);
      } else {
        list = this.matterModalities(egress_profile_matter.modalities).map(
          (element) => element.order
        );
      }
      list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
      return list.join(", ");
    },
    semesterClassroonHours(egress_profile_matter) {
      if (!this.profileType) return 0;
      let total_hours = 0;
      const pedagogical_hours_ids = this.time_allocations
        .filter((x) => x.uses_module_minutes == true)
        .map((x) => x.id);
      if (egress_profile_matter.use_matter_values) {
        total_hours = this.matter_time_allocations
          .filter(
            (x) =>
              x.matter == egress_profile_matter.matter &&
              pedagogical_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          )
          .reduce((acc, obj) => {
            let current_hour = obj.hours;
            if (
              this.institution &&
              parseInt(this.institution.hour_decimal_places) === 0
            )
              current_hour = Math.round(current_hour);
            return parseFloat(acc) + parseFloat(current_hour);
          }, 0);
      } else {
        total_hours = this.ep_matter_time_allocations
          .filter(
            (x) =>
              x.egress_profile_matter == egress_profile_matter.id &&
              pedagogical_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          )
          .reduce((acc, obj) => {
            let current_hour = obj.hours;
            if (
              this.institution &&
              parseInt(this.institution.hour_decimal_places) === 0
            )
              current_hour = Math.round(current_hour);
            return parseFloat(acc) + parseFloat(current_hour);
          }, 0);
      }
      if (
        this.institution &&
        parseInt(this.institution.hour_decimal_places) === 0
      ) {
        return parseFloat(Math.round(total_hours));
      }
      return parseFloat(
        total_hours.toFixed(
          this.institution && parseInt(this.institution.hour_decimal_places) > 0
            ? parseInt(this.institution.hour_decimal_places)
            : 2
        )
      );
    },
  },
  created() {},
};
</script>

<style scoped>
.matters-table-class {
  display: none;
}
.pointer {
  cursor: pointer;
}
@media print {
  .matters-table-class {
    display: block;
  }
}
</style>

